.navItem {
	align-items: center;
	display: flex;
	flex-shrink: 0;
	justify-content: space-between;
	color: var(--black);

	@media (--large) {
		border: none;
		height: 100%;
		color: var(--token-color-palette-neutral-600);
		position: relative;
	}

	& .panel {
		left: 0;
		opacity: 1;

		@media (--large) {
			left: calc(var(--hdsplus-spacing-12) * -1);
		}
	}

	&:not(.active) .mobilePanel {
		opacity: 0;
		visibility: hidden;
	}

	&.active {
		& .mobilePanel {
			left: 0;
			opacity: 1;
		}
	}

	&:not(.mobileActive) {
		& .mobileNavItemTrigger {
			opacity: 0;
			visibility: hidden;
		}
	}

	&.mobileActive {
		& .mobileNavItemTrigger {
			left: 0;
			opacity: 1;
		}
	}

	color: var(--token-color-palette-neutral-700);
}

.mobilePanel,
.panel {
	background: var(--token-color-surface-primary);
	bottom: 0;
	left: 20%;
	overflow-x: hidden;
	overflow-y: auto;
	position: absolute;
	right: 0;
	top: 0;
	transition: left 0.2s, opacity 0.2s, visibility 0.2s;
	transition-timing-function: cubic-bezier(0.5, 0.2, 0.2, 0.5);
	width: 100%;
	border-top: 1px solid var(--token-color-border-faint);

	@media (--large) {
		/* Ensures the panel height is flexible based on its content */
		height: max-content;
		left: 0;
		background: transparent;
		z-index: 2;
		width: max-content;
		border-radius: var(--hdsplus-spacing-03);
		border-top: none;
	}

	@media (prefers-reduced-motion) {
		transition: none;
	}
}

.panel {
	display: none;

	@media (--large) {
		display: block;
		animation-duration: 175ms;
		animation-timing-function: ease;

		@media (prefers-reduced-motion) {
			animation: none;
		}

		&[data-motion='from-start'] {
			animation-name: enterFromLeft;
		}
		&[data-motion='from-end'] {
			animation-name: enterFromRight;
		}
		&[data-motion='to-start'] {
			animation-name: exitToLeft;
		}
		&[data-motion='to-end'] {
			animation-name: exitToRight;
		}
	}
}

.mobilePanel {
	@media (--large) {
		display: none;
	}
}

.panelInner {
	display: flex;
}

.panelContent {
	position: relative;
	width: 100%;

	@media (--large) {
		display: flex;
		gap: var(--hdsplus-spacing-08);
		line-height: 24px;
	}
}

.navItemTrigger,
.mobileNavItemTrigger {
	align-items: center;
	width: 100%;
	color: inherit;
	background-color: var(--token-color-surface-primary);
	border-style: none;
	cursor: pointer;
	font: inherit;
	justify-content: space-between;
}

.navItemTrigger {
	display: none;
	cursor: default;

	@media (--large) {
		display: flex;
		padding: var(--hdsplus-spacing-03) var(--hdsplus-spacing-04);
		border-radius: 4px;
		height: auto;
		font-weight: var(--font-weight-medium);
		&:hover {
			background-color: var(--token-color-palette-neutral-100);
		}
	}

	&:focus {
		outline-offset: -5px;
		outline: 2px solid var(--token-color-focus-action-internal);
		outline-style: auto;
	}
}

.navItemLink {
	cursor: pointer;

	@media (--small) {
		display: flex;
		border-bottom: 1px solid var(--token-color-border-faint);
		font-weight: var(--font-weight-semi-bold);
		height: var(--hdsplus-spacing-12);
		gap: var(--hdsplus-spacing-03);
		padding: 0 var(--hdsplus-spacing-07) 0 var(--hdsplus-spacing-05);	
		width: 100%;
	}
}

.mobileNavItemTrigger {
	display: flex;
	border-bottom: 1px solid var(--token-color-border-faint);
	font-weight: var(--font-weight-semi-bold);
	height: var(--hdsplus-spacing-12);
	gap: var(--hdsplus-spacing-03);
	padding: 0 var(--hdsplus-spacing-07) 0 var(--hdsplus-spacing-05);

	@media (--large) {
		display: none;
	}

	& > svg {
		transition: transform 0.3s;

		@media (prefers-reduced-motion) {
			transition: none;
		}

		& > path {
			transition: stroke 0.3s;
		}
	}
}

@keyframes enterFromRight {
	from {
		opacity: 0;
		transform: translateX(200px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes enterFromLeft {
	from {
		opacity: 0;
		transform: translateX(-200px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes exitToRight {
	from {
		opacity: 1;
		transform: translateX(0);
	}
	to {
		opacity: 0;
		transform: translateX(200px);
	}
}

@keyframes exitToLeft {
	from {
		opacity: 1;
		transform: translateX(0);
	}
	to {
		opacity: 0;
		transform: translateX(-200px);
	}
}
