/*

Our design token system defines the `display-expressive-{size}-font-family
variables in the :root scope, but the Next.js generated CSS variable is defined
on a DOM element. This means that if the CSS variables referenced the generated
variable it would not resolve correctly, as that variable is defined lower in
the cascade. To work around this, we redefine the font-family variables on the
top-most DOM element so that as the browser searches up the cascade for each
variable it will find the correct value.

*/
.hashicorpSans {
	--hdsplus-typography-display-expressive-100-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-display-expressive-200-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-display-expressive-300-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-display-expressive-400-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-display-expressive-500-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-display-expressive-600-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-display-expressive-700-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-display-expressive-800-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-label-font-family: var(--hashicorp-sans);
	--font-display: var(--hashicorp-sans);
}

.gilmer {
	--hdsplus-typography-display-expressive-100-font-family: var(--gilmer);
	--hdsplus-typography-display-expressive-200-font-family: var(--gilmer);
	--hdsplus-typography-display-expressive-300-font-family: var(--gilmer);
	--hdsplus-typography-display-expressive-400-font-family: var(--gilmer);
	--hdsplus-typography-display-expressive-500-font-family: var(--gilmer);
	--hdsplus-typography-display-expressive-600-font-family: var(--gilmer);
	--hdsplus-typography-display-expressive-700-font-family: var(--gilmer);
	--hdsplus-typography-display-expressive-800-font-family: var(--gilmer);
	--hdsplus-typography-label-font-family: var(--gilmer);
	--font-display: var(--gilmer);
}
