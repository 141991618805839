.viewportPos {
	position: absolute;
	justify-content: center;
	width: 100%;
	top: 100%;
	left: 0;
}

.viewport {
	position: relative;
	margin-top: var(--hdsplus-spacing-03);
	width: var(--radix-navigation-menu-viewport-width);
	background: var(--token-color-surface-primary);
	border-radius: var(--hdsplus-spacing-03);
	overflow: hidden;
	box-shadow: var(--token-elevation-high-box-shadow);
	height: var(--radix-navigation-menu-viewport-height);
	transform-origin: top center;
	transition: opacity, width, height, 300ms ease;
	transform: translateX(var(--hashi-navigation-menu-translate));

	@media (prefers-reduced-motion: reduce) {
		transition: none;
	}
}

.viewport[data-state='open'] {
	animation-name: scaleIn;
	animation-duration: 275ms;
	animation-timing-function: ease;

	@media (prefers-reduced-motion: reduce) {
		animation: none;
	}
}

@keyframes scaleIn {
	from {
		opacity: 0.88;
		scale: 0.96;
	}
	to {
		opacity: 1;
		scale: 1;
	}
}

.nav {
	composes: g-type-body-strong from global;
	background: var(--token-color-surface-primary);
	color: var(--token-color-foreground-strong);
	position: relative;
	z-index: 666666667; /* higher than OptinMonster */
}

.navInner {
	align-items: center;
	background: var(--token-color-surface-primary);
	display: flex;
	height: 64px;
	justify-content: space-between;
	padding-right: var(--hdsplus-spacing-07);
	padding-left: var(--hdsplus-spacing-05);
	position: relative;
	z-index: 3;
	gap: var(--hdsplus-spacing-07);
	margin: 0;

	@media (--medium-up) {
		padding-right: var(--hdsplus-spacing-05);
	}

	@media (--large) {
		transition: none;
		width: 100%;
	}

	&.isActive {
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;

		@media (--large) {
			position: relative;
		}

		& .links {
			opacity: 1;
		}
	}
}

.logo {
	display: block;
	height: 37px;
	width: auto;

	& img {
		width: unset;
		height: 100%;
	}
}

.ctasMobile {
	display: flex;
	gap: var(--hdsplus-spacing-05);

	@media (--large) {
		display: none;
	}
}

.ctaMobilePrimary {
	display: none;

	@media (--medium-up) {
		display: block;
	}
}

.ctaMobileSecondary {
	display: none;

	@media (min-width: 500px) {
		display: block;
	}
}

.mobileToggleContainer {
	display: flex;
	align-items: center;
}

.mobileToggle {
	background: none;
	border: 0;
	bottom: 0;
	cursor: pointer;
	width: 24px;
	justify-content: center;
	align-items: center;
	display: flex;
	line-height: 1;
	padding: 0;
	color: var(--black);
	list-style: none;

	@media (--large) {
		display: none;
	}

	& svg:last-child {
		display: none;
	}

	&.isActive svg {
		&:first-child {
			display: none;
		}

		&:last-child {
			display: block;
		}
	}
}

.links {
	background: var(--token-color-surface-primary);
	height: calc(100vh - 63px);
	left: 0;
	opacity: 0;
	position: fixed;
	top: 63px;
	width: 100%;
	z-index: 2;
	display: none;

	&.isActive {
		opacity: 1;
		background-color: var(--token-color-surface-faint);

		@media not all and (--large) {
			display: block;
		}

		@media (--large) {
			background-color: var(--token-color-surface-primary);
		}
	}

	@media (--large) {
		display: flex;
		font-size: 0.938rem;
		height: auto;
		justify-content: space-between;
		line-height: 1.6em;
		opacity: 1;
		padding: 0;
		position: static;
	}

	& > div {
		height: inherit;
	}
}

.linksList {
	align-items: center;
	list-style: none;
	margin: 0;
	padding: 0;
	border-top: 1px solid var(--token-color-border-faint);

	@media (--large) {
		display: flex;
		border-top: none;
	}

	&:first-child {
		flex-grow: 1;
	}
}

.ctasMobileInnerWrapper {
	display: none;

	&.isActive {
		display: block;
		position: fixed;
		width: 100%;
		bottom: 0;
		left: 0;
		z-index: 3;
		background: var(--token-color-surface-faint);

		@media (--large) {
			display: none;
		}
	}
}

.ctasMobileInner {
	display: grid;
	gap: var(--hdsplus-spacing-05);
	padding: var(--hdsplus-spacing-07);
	list-style: none;

	@media (min-width: 551px) {
		display: flex;
		justify-content: flex-end;
		flex-wrap: nowrap;
	}
}

.ctaMobileInner {
	flex-basis: 100%;
	margin: auto 0;

	@media (min-width: 551px) {
		flex-basis: auto;
	}
}

.ctaMobileInnerPrimary {
	background-color: var(--token-color-surface-primary);
	border-radius: 4px;
}

.ctasDesktop {
	display: none;

	@media (--large) {
		display: block;
	}
}

.ctasWrapper {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;
	gap: var(--hdsplus-spacing-05);
	width: 100%;
}

.cta {
	list-style: none;
	& a {
		white-space: nowrap;
		width: 100%;
		display: grid;
		grid-auto-flow: column;
	}

	& span {
		line-height: 20px;
	}
}

.navBarToggle {
	list-style: none;
	& .breadcrumb {
		display: none;
	}

	&.isBack {
		& a {
			display: none;
		}

		& .breadcrumb {
			display: flex;
		}
	}

	& a {
		&:focus {
			outline: 2px solid var(--token-color-focus-action-internal);
			outline-style: auto;
		}
	}
}

.breadcrumb {
	color: var(--token-color-foreground-action);
	cursor: pointer;
	font-weight: var(--font-weight-medium);
	padding: 0;
	gap: 0;

	@media (prefers-reduced-motion) {
		transition: none;
	}

	& svg {
		margin-top: var(--hdsplus-spacing-01);
		margin-left: calc(var(--hdsplus-spacing-04) * -1);

		& [fill] {
			fill: var(--token-color-foreground-action);
		}
		& [stroke] {
			stroke: var(--token-color-foreground-action);
		}
	}
}
