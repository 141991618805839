.style_viewportPos__bluVT {
	position: absolute;
	justify-content: center;
	width: 100%;
	top: 100%;
	left: 0;
}

.style_viewport__7svsF {
	position: relative;
	margin-top: var(--hdsplus-spacing-03);
	width: var(--radix-navigation-menu-viewport-width);
	background: var(--token-color-surface-primary);
	border-radius: var(--hdsplus-spacing-03);
	overflow: hidden;
	box-shadow: var(--token-elevation-high-box-shadow);
	height: var(--radix-navigation-menu-viewport-height);
	transform-origin: top center;
	transition: opacity, width, height, 300ms ease;
	transform: translateX(var(--hashi-navigation-menu-translate));
}

@media (prefers-reduced-motion: reduce) {

.style_viewport__7svsF {
		transition: none;
}
	}

.style_viewport__7svsF[data-state='open'] {
	animation-name: style_scaleIn__dA5S0;
	animation-duration: 275ms;
	animation-timing-function: ease;
}

@media (prefers-reduced-motion: reduce) {

.style_viewport__7svsF[data-state='open'] {
		animation: none;
}
	}

@keyframes style_scaleIn__dA5S0 {
	from {
		opacity: 0.88;
		scale: 0.96;
	}
	to {
		opacity: 1;
		scale: 1;
	}
}

.style_nav__xANUb {
	background: var(--token-color-surface-primary);
	color: var(--token-color-foreground-strong);
	position: relative;
	z-index: 666666667; /* higher than OptinMonster */
}

.style_navInner__3s2Fq {
	align-items: center;
	background: var(--token-color-surface-primary);
	display: flex;
	height: 64px;
	justify-content: space-between;
	padding-right: var(--hdsplus-spacing-07);
	padding-left: var(--hdsplus-spacing-05);
	position: relative;
	z-index: 3;
	gap: var(--hdsplus-spacing-07);
	margin: 0;
}

@media (min-width: 768px) {

.style_navInner__3s2Fq {
		padding-right: var(--hdsplus-spacing-05);
}
	}

@media (min-width: 1120px) {

.style_navInner__3s2Fq {
		transition: none;
		width: 100%;
}
	}

.style_navInner__3s2Fq.style_isActive__yC2kA {
		bottom: 0;
		left: 0;
		position: fixed;
		right: 0;
		top: 0;
	}

@media (min-width: 1120px) {

.style_navInner__3s2Fq.style_isActive__yC2kA {
			position: relative;
	}
		}

.style_navInner__3s2Fq.style_isActive__yC2kA .style_links__R8OKi {
			opacity: 1;
		}

.style_logo__G4wWQ {
	display: block;
	height: 37px;
	width: auto;
}

.style_logo__G4wWQ img {
		width: auto;
		width: initial;
		height: 100%;
	}

.style_ctasMobile__ugK4L {
	display: flex;
	gap: var(--hdsplus-spacing-05);
}

@media (min-width: 1120px) {

.style_ctasMobile__ugK4L {
		display: none;
}
	}

.style_ctaMobilePrimary__Wgy87 {
	display: none;
}

@media (min-width: 768px) {

.style_ctaMobilePrimary__Wgy87 {
		display: block;
}
	}

.style_ctaMobileSecondary__OkDJl {
	display: none;
}

@media (min-width: 500px) {

.style_ctaMobileSecondary__OkDJl {
		display: block;
}
	}

.style_mobileToggleContainer__MJO6g {
	display: flex;
	align-items: center;
}

.style_mobileToggle__vuJaE {
	background: none;
	border: 0;
	bottom: 0;
	cursor: pointer;
	width: 24px;
	justify-content: center;
	align-items: center;
	display: flex;
	line-height: 1;
	padding: 0;
	color: var(--black);
	list-style: none;
}

@media (min-width: 1120px) {

.style_mobileToggle__vuJaE {
		display: none;
}
	}

.style_mobileToggle__vuJaE svg:last-child {
		display: none;
	}

.style_mobileToggle__vuJaE.style_isActive__yC2kA svg:first-child {
			display: none;
		}

.style_mobileToggle__vuJaE.style_isActive__yC2kA svg:last-child {
			display: block;
		}

.style_links__R8OKi {
	background: var(--token-color-surface-primary);
	height: calc(100vh - 63px);
	left: 0;
	opacity: 0;
	position: fixed;
	top: 63px;
	width: 100%;
	z-index: 2;
	display: none;
}

.style_links__R8OKi.style_isActive__yC2kA {
		opacity: 1;
		background-color: var(--token-color-surface-faint);
	}

@media not all and (min-width: 1120px) {

.style_links__R8OKi.style_isActive__yC2kA {
			display: block;
	}
		}

@media (min-width: 1120px) {

.style_links__R8OKi.style_isActive__yC2kA {
			background-color: var(--token-color-surface-primary);
	}
		}

@media (min-width: 1120px) {

.style_links__R8OKi {
		display: flex;
		font-size: 0.938rem;
		height: auto;
		justify-content: space-between;
		line-height: 1.6em;
		opacity: 1;
		padding: 0;
		position: static;
}
	}

.style_links__R8OKi > div {
		height: inherit;
	}

.style_linksList__fPfyB {
	align-items: center;
	list-style: none;
	margin: 0;
	padding: 0;
	border-top: 1px solid var(--token-color-border-faint);
}

@media (min-width: 1120px) {

.style_linksList__fPfyB {
		display: flex;
		border-top: none;
}
	}

.style_linksList__fPfyB:first-child {
		flex-grow: 1;
	}

.style_ctasMobileInnerWrapper__sF2Z_ {
	display: none;
}

.style_ctasMobileInnerWrapper__sF2Z_.style_isActive__yC2kA {
		display: block;
		position: fixed;
		width: 100%;
		bottom: 0;
		left: 0;
		z-index: 3;
		background: var(--token-color-surface-faint);
	}

@media (min-width: 1120px) {

.style_ctasMobileInnerWrapper__sF2Z_.style_isActive__yC2kA {
			display: none;
	}
		}

.style_ctasMobileInner__0Daxg {
	display: grid;
	grid-gap: var(--hdsplus-spacing-05);
	gap: var(--hdsplus-spacing-05);
	padding: var(--hdsplus-spacing-07);
	list-style: none;
}

@media (min-width: 551px) {

.style_ctasMobileInner__0Daxg {
		display: flex;
		justify-content: flex-end;
		flex-wrap: nowrap;
}
	}

.style_ctaMobileInner__kdiM9 {
	flex-basis: 100%;
	margin: auto 0;
}

@media (min-width: 551px) {

.style_ctaMobileInner__kdiM9 {
		flex-basis: auto;
}
	}

.style_ctaMobileInnerPrimary__kcA_p {
	background-color: var(--token-color-surface-primary);
	border-radius: 4px;
}

.style_ctasDesktop__CCm9W {
	display: none;
}

@media (min-width: 1120px) {

.style_ctasDesktop__CCm9W {
		display: block;
}
	}

.style_ctasWrapper____gs2 {
	display: flex;
	flex-wrap: nowrap;
	justify-content: flex-end;
	align-items: center;
	gap: var(--hdsplus-spacing-05);
	width: 100%;
}

.style_cta__RACUz {
	list-style: none;
}

.style_cta__RACUz a {
		white-space: nowrap;
		width: 100%;
		display: grid;
		grid-auto-flow: column;
	}

.style_cta__RACUz span {
		line-height: 20px;
	}

.style_navBarToggle__IMm0L {
	list-style: none;
}

.style_navBarToggle__IMm0L .style_breadcrumb__pFWs8 {
		display: none;
	}

.style_navBarToggle__IMm0L.style_isBack__fNbYB a {
			display: none;
		}

.style_navBarToggle__IMm0L.style_isBack__fNbYB .style_breadcrumb__pFWs8 {
			display: flex;
		}

.style_navBarToggle__IMm0L a:focus {
			outline: 2px solid var(--token-color-focus-action-internal);
			outline-style: auto;
		}

.style_breadcrumb__pFWs8 {
	color: var(--token-color-foreground-action);
	cursor: pointer;
	font-weight: var(--font-weight-medium);
	padding: 0;
	gap: 0;
}

@media (prefers-reduced-motion) {

.style_breadcrumb__pFWs8 {
		transition: none;
}
	}

.style_breadcrumb__pFWs8 svg {
		margin-top: var(--hdsplus-spacing-01);
		margin-left: calc(var(--hdsplus-spacing-04) * -1);
	}

.style_breadcrumb__pFWs8 svg [fill] {
			fill: var(--token-color-foreground-action);
		}

.style_breadcrumb__pFWs8 svg [stroke] {
			stroke: var(--token-color-foreground-action);
		}

.style_navItem__4XkTC {
	align-items: center;
	display: flex;
	flex-shrink: 0;
	justify-content: space-between;
	color: var(--black);
}

@media (min-width: 1120px) {

.style_navItem__4XkTC {
		border: none;
		height: 100%;
		color: var(--token-color-palette-neutral-600);
		position: relative;
}
	}

.style_navItem__4XkTC .style_panel__uH366 {
		left: 0;
		opacity: 1;
	}

@media (min-width: 1120px) {

.style_navItem__4XkTC .style_panel__uH366 {
			left: calc(var(--hdsplus-spacing-12) * -1);
	}
		}

.style_navItem__4XkTC:not(.style_active__0Zh60) .style_mobilePanel__JAy9J {
		opacity: 0;
		visibility: hidden;
	}

.style_navItem__4XkTC.style_active__0Zh60 .style_mobilePanel__JAy9J {
			left: 0;
			opacity: 1;
		}

.style_navItem__4XkTC:not(.style_mobileActive__OxM9J) .style_mobileNavItemTrigger__5OlYm {
			opacity: 0;
			visibility: hidden;
		}

.style_navItem__4XkTC.style_mobileActive__OxM9J .style_mobileNavItemTrigger__5OlYm {
			left: 0;
			opacity: 1;
		}

.style_navItem__4XkTC {

	color: var(--token-color-palette-neutral-700);
}

.style_mobilePanel__JAy9J,
.style_panel__uH366 {
	background: var(--token-color-surface-primary);
	bottom: 0;
	left: 20%;
	overflow-x: hidden;
	overflow-y: auto;
	position: absolute;
	right: 0;
	top: 0;
	transition: left 0.2s, opacity 0.2s, visibility 0.2s;
	transition-timing-function: cubic-bezier(0.5, 0.2, 0.2, 0.5);
	width: 100%;
	border-top: 1px solid var(--token-color-border-faint);
}

@media (min-width: 1120px) {

.style_mobilePanel__JAy9J,
.style_panel__uH366 {
		/* Ensures the panel height is flexible based on its content */
		height: -moz-max-content;
		height: max-content;
		left: 0;
		background: transparent;
		z-index: 2;
		width: -moz-max-content;
		width: max-content;
		border-radius: var(--hdsplus-spacing-03);
		border-top: none;
}
	}

@media (prefers-reduced-motion) {

.style_mobilePanel__JAy9J,
.style_panel__uH366 {
		transition: none;
}
	}

.style_panel__uH366 {
	display: none;
}

@media (min-width: 1120px) {

.style_panel__uH366 {
		display: block;
		animation-duration: 175ms;
		animation-timing-function: ease;
}
	}

@media (min-width: 1120px) and (prefers-reduced-motion) {

.style_panel__uH366 {
			animation: none;
}
		}

@media (min-width: 1120px) {

		.style_panel__uH366[data-motion='from-start'] {
			animation-name: style_enterFromLeft__AXKts;
		}
		.style_panel__uH366[data-motion='from-end'] {
			animation-name: style_enterFromRight__MUl32;
		}
		.style_panel__uH366[data-motion='to-start'] {
			animation-name: style_exitToLeft__hmmh5;
		}
		.style_panel__uH366[data-motion='to-end'] {
			animation-name: style_exitToRight__DsoXJ;
		}
	}

@media (min-width: 1120px) {

.style_mobilePanel__JAy9J {
		display: none;
}
	}

.style_panelInner__TvqMP {
	display: flex;
}

.style_panelContent__n1buH {
	position: relative;
	width: 100%;
}

@media (min-width: 1120px) {

.style_panelContent__n1buH {
		display: flex;
		gap: var(--hdsplus-spacing-08);
		line-height: 24px;
}
	}

.style_navItemTrigger__65Jsv,
.style_mobileNavItemTrigger__5OlYm {
	align-items: center;
	width: 100%;
	color: inherit;
	background-color: var(--token-color-surface-primary);
	border-style: none;
	cursor: pointer;
	font: inherit;
	justify-content: space-between;
}

.style_navItemTrigger__65Jsv {
	display: none;
	cursor: default;
}

@media (min-width: 1120px) {

.style_navItemTrigger__65Jsv {
		display: flex;
		padding: var(--hdsplus-spacing-03) var(--hdsplus-spacing-04);
		border-radius: 4px;
		height: auto;
		font-weight: var(--font-weight-medium);
}
		.style_navItemTrigger__65Jsv:hover {
			background-color: var(--token-color-palette-neutral-100);
		}
	}

.style_navItemTrigger__65Jsv:focus {
		outline-offset: -5px;
		outline: 2px solid var(--token-color-focus-action-internal);
		outline-style: auto;
	}

.style_navItemLink__t1wPJ {
	cursor: pointer;
}

@media (max-width: 767px) {

.style_navItemLink__t1wPJ {
		display: flex;
		border-bottom: 1px solid var(--token-color-border-faint);
		font-weight: var(--font-weight-semi-bold);
		height: var(--hdsplus-spacing-12);
		gap: var(--hdsplus-spacing-03);
		padding: 0 var(--hdsplus-spacing-07) 0 var(--hdsplus-spacing-05);	
		width: 100%;
}
	}

.style_mobileNavItemTrigger__5OlYm {
	display: flex;
	border-bottom: 1px solid var(--token-color-border-faint);
	font-weight: var(--font-weight-semi-bold);
	height: var(--hdsplus-spacing-12);
	gap: var(--hdsplus-spacing-03);
	padding: 0 var(--hdsplus-spacing-07) 0 var(--hdsplus-spacing-05);
}

@media (min-width: 1120px) {

.style_mobileNavItemTrigger__5OlYm {
		display: none;
}
	}

.style_mobileNavItemTrigger__5OlYm > svg {
		transition: transform 0.3s;
	}

@media (prefers-reduced-motion) {

.style_mobileNavItemTrigger__5OlYm > svg {
			transition: none;
	}
		}

.style_mobileNavItemTrigger__5OlYm > svg > path {
			transition: stroke 0.3s;
		}

@keyframes style_enterFromRight__MUl32 {
	from {
		opacity: 0;
		transform: translateX(200px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes style_enterFromLeft__AXKts {
	from {
		opacity: 0;
		transform: translateX(-200px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes style_exitToRight__DsoXJ {
	from {
		opacity: 1;
		transform: translateX(0);
	}
	to {
		opacity: 0;
		transform: translateX(200px);
	}
}

@keyframes style_exitToLeft__hmmh5 {
	from {
		opacity: 1;
		transform: translateX(0);
	}
	to {
		opacity: 0;
		transform: translateX(-200px);
	}
}

@font-face {
font-family: '__hashicorpSans_b1925c';
src: url(/_next/static/media/d29050812a1756cf-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 300 600;
}@font-face {font-family: '__hashicorpSans_Fallback_b1925c';src: local("Arial");ascent-override: 100.84%;descent-override: 31.30%;line-gap-override: 0.00%;size-adjust: 100.65%
}.__className_b1925c {font-family: '__hashicorpSans_b1925c', '__hashicorpSans_Fallback_b1925c'
}.__variable_b1925c {--hashicorp-sans: '__hashicorpSans_b1925c', '__hashicorpSans_Fallback_b1925c'
}

/*

Our design token system defines the `display-expressive-{size}-font-family
variables in the :root scope, but the Next.js generated CSS variable is defined
on a DOM element. This means that if the CSS variables referenced the generated
variable it would not resolve correctly, as that variable is defined lower in
the cascade. To work around this, we redefine the font-family variables on the
top-most DOM element so that as the browser searches up the cascade for each
variable it will find the correct value.

*/
.style_hashicorpSans__8YLQu {
	--hdsplus-typography-display-expressive-100-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-display-expressive-200-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-display-expressive-300-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-display-expressive-400-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-display-expressive-500-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-display-expressive-600-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-display-expressive-700-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-display-expressive-800-font-family: var(
		--hashicorp-sans
	);
	--hdsplus-typography-label-font-family: var(--hashicorp-sans);
	--font-display: var(--hashicorp-sans);
}

.style_gilmer__irJYu {
	--hdsplus-typography-display-expressive-100-font-family: var(--gilmer);
	--hdsplus-typography-display-expressive-200-font-family: var(--gilmer);
	--hdsplus-typography-display-expressive-300-font-family: var(--gilmer);
	--hdsplus-typography-display-expressive-400-font-family: var(--gilmer);
	--hdsplus-typography-display-expressive-500-font-family: var(--gilmer);
	--hdsplus-typography-display-expressive-600-font-family: var(--gilmer);
	--hdsplus-typography-display-expressive-700-font-family: var(--gilmer);
	--hdsplus-typography-display-expressive-800-font-family: var(--gilmer);
	--hdsplus-typography-label-font-family: var(--gilmer);
	--font-display: var(--gilmer);
}

